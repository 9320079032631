import React, { useCallback, useMemo, useRef, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Editable, Slate } from 'slate-react'
import { createEditor } from 'slate'
import { useDebounce } from "use-debounce";

import withCommon from "./hooks/withCommon";
import { getBlock, getMarked } from "./utils/chatEditor/SlateUtilityFunctions";
import MiniTextFormat from "./Toolbar/PopupTool/MiniTextFormat";
import { commands, mentionsEvent } from "./utils/events";
import { insertEmoji } from "./utils/emoji";
import { draftToSlate } from "./utils/draftToSlate";
import MentionsPopup from "./common/MentionsPopup";
import { serializeToText } from "./utils/serializeToText";
import useMentions from "./hooks/useMentions";
import Shorthands from "./common/Shorthands";
import usePopupStyle from "./Toolbar/PopupTool/PopupToolStyle";
import { EditorProvider } from "./hooks/useMouseMove";


const ChatEditor = forwardRef((props, ref) => {
    const { id, theme, content, readOnly, otherProps, needLayout=false, toolBar=true, onSave, onsubmit } = props;
    const classes = usePopupStyle(theme);
    const convertedContent = draftToSlate({ data: content });
    const [isInteracted, setIsInteracted] = useState(false);
    const [value, setValue] = useState(convertedContent);
    const [loadedValue] = useState(value);
    const [deboundedValue] = useDebounce(value, 500);
    const editor = useMemo(() => {
        return withCommon(createEditor(), { needLayout });
      }, []);
    const isReadOnly = readOnly === "readonly";

    useImperativeHandle(ref, () => ({
        emojiClick: (emoji) => {
            insertEmoji(editor, emoji?.native, editor.selection);
        },
      }));

    useEffect(() => {
        setValue(draftToSlate({ data: content }));
    }, [id, content]);

    useEffect(() => {
        if (JSON.stringify(loadedValue) !== JSON.stringify(deboundedValue) &&
          isInteracted &&
          onSave
        ) {
          const { value: strVal, ...restVal } = getOnSaveData(deboundedValue);
          onSave(strVal, restVal);
        }
      }, [deboundedValue]);

      const getOnSaveData = (val) => {
        const text = serializeToText(val);      
        const title = val?.find((f) => f.type === "title");
        return {
          value: JSON.stringify(val),
          text: text,
          title: serializeToText(title?.children) || "Untitled",
        };
      };

    
    const {
        CHARACTERS = [],
        hideTools,
        // needLayout = true,
    } = otherProps || {};

    const mentionsRef = useRef();

    const customProps = {
        ...(otherProps || {}),
        readOnly: isReadOnly,
        editorPlaceholder: "Write Something",
        page_id: 1,
    };

    const [mentions, setMentions] = useMentions({
        editor,
        selection: editor?.selection,
    });

    const { search, target, index } = mentions;
    let { type } = mentions;
    if(type && type === "elements" && hideTools.indexOf("slash") > -1) {
        type = null
    }
    const chars = type ? Shorthands[type]({ ...mentions, CHARACTERS, hideTools: hideTools }) : [];

    const Leaf = ({ attributes, children, leaf }) => {
        children = getMarked(leaf, children);
        return <span {...attributes}>{children}</span>;
    };

    const handleEditorChange = (newValue) => {
        setValue(newValue);
        if (!isInteracted) {
            setIsInteracted(true);
        }
    };

    const Element = (props) => {
        return getBlock(props);
    };
    const renderElement = useCallback((props) => {
        return <Element {...props} customProps={customProps} />;
    }, []);
    const renderLeaf = useCallback((props) => {
        return <Leaf {...props} customProps={customProps} />;
    }, []);

    const onKeyDown = useCallback(
        (event) => {
            const isMetaKey = event.metaKey && event.keyCode >= 65 && event.keyCode <= 90;
            const isCtrlKey = event.ctrlKey || isMetaKey;
            if (target && chars.length > 0 && !isCtrlKey) {
                mentionsEvent({
                    event,
                    mentions,
                    setMentions,
                    chars,
                    target,
                    editor,
                    type,
                    mentionsRef,
                });
            } else if (isCtrlKey) {
                commands({
                    event,
                    editor,
                });
            } else if (event.key === "Enter" && !event.shiftKey) {
                const { value: strVal, ...restVal } = getOnSaveData(value);
                onsubmit(false, {strVal, restVal})
              }
        },
        [chars, editor, target, mentions, setMentions, search, type, mentionsRef]
    );

    const handleClose = () => {

      };

    return (
        <EditorProvider theme={theme} editor={editor}>
            <Slate
                key={id}
                editor={editor}
                initialValue={value}
                onChange={handleEditorChange}
            >
                {toolBar && <MiniTextFormat classes={classes} editor={editor} closeMainPopup={handleClose} /> }
                <Editable
                    className={"chatEditorRoot"}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder="Start typing ..."
                    spellCheck
                    autoFocus
                    onKeyDown={onKeyDown}
                />
                {!readOnly ? (
                    <MentionsPopup
                        ref={mentionsRef}
                        mentions={mentions}
                        setMentions={setMentions}
                        editor={editor}
                        target={target}
                        index={index}
                        chars={chars}
                        type={type}
                        theme={theme}
                    />
                ) : null}
            </Slate>
        </EditorProvider>
    )
})

ChatEditor.displayName = "ChatEditor";

export default ChatEditor;