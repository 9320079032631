import { Transforms } from "slate";
import { formField } from "./formfield";
import insertNewLine from "./insertNewLine";

export const insertForm = (editor, item) => {
  const grid = !item
    ? {
      type: "form",
      grid: "container",
      formName: `form_${new Date().getTime()}`,
      uid: `form_${new Date().getTime()}`,
      props: {
        onSubmit: null,
      },
      buttonProps: {
        bannerSpacing: {
          left: 12,
          right: 12,
          top: 12,
          bottom: 12,
        },
      },
      workflow: [],
      children: [{ ...formField() }],
      metadatamapping: "mappingToContactBoard",
    }
    : item;
  Transforms.insertNodes(editor, grid);
  insertNewLine(editor);
};
