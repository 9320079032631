import {
  ActionItemsIcon,
  BookIcon,
  CheckIcon,
  // ClarifyIcon,
  CloseIcon,
  DeleteIcon,
  // EditIcon,
  MakeShorterIcon,
  SummarizeIcon,
  TextAlignLeftIcon,
  ToneIcon,
  TranslateIcon,
  TryAgainIcon,
} from "../../assets/svg/AIIcons";

export const MODES = {
  default: 0,
  translate: 1,
  summarize: 2,
  actionItems: 3,
  grammatical: 4,
  rephraseTone: 5,
  longer: 6,
  shorter: 7,
};

// const improveWriting = {
//   label: "Improve writing",
//   value: "improve_writing",
//   Icon: EditIcon,
//   replace: true,
// };

const commonOptions = [
  {
    label: "Make longer",
    value: "make_longer",
    Icon: TextAlignLeftIcon,
    replace: false,
    mode: MODES.longer,
  },
  {
    label: "Make shorter",
    value: "make_shorter",
    Icon: MakeShorterIcon,
    replace: false,
    mode: MODES.shorter,
  },
  // {
  //   label: "Continue writing",
  //   value: "continue_writing",
  //   Icon: EditIcon,
  //   replace: false,
  // },
];

export const newContentOptions = [
  {
    group: "",
    groupLabel: "",
    options: [
      // improveWriting,
      ...commonOptions.map((o) => ({ ...o, replace: true })),
      {
        label: "Close",
        value: "close",
        Icon: CloseIcon,
        replace: true,
      },
    ],
  },
];

const languages = [
  "English",
  "Korean",
  "Chinese",
  "Japanese",
  "Russian",
  "French",
  "Portuguese",
  "German",
  "Italian",
  "Dutch",
  "Indonesian",
];

const translateOptions = [
  {
    options: languages.map((l) => ({
      label: l,
      value: l,
      replace: false,
      mode: MODES.translate,
    })),
  },
];

const tones = ["Professional", "Casual", "Straightforward", "Friendly"];

const toneOptions = [
  {
    options: tones.map((t) => ({
      label: t,
      value: t,
      replace: false,
      mode: MODES.rephraseTone,
    })),
  },
];

export const editContentOptions = [
  {
    group: "",
    groupLabel: "Regenerate page",
    options: [
      {
        label: "Translate",
        value: "translate",
        Icon: TranslateIcon,
        options: translateOptions,
        replace: false,
      },
      {
        label: "Summarize",
        value: "summarize",
        Icon: SummarizeIcon,
        replace: false,
        mode: MODES.summarize,
      },
      // {
      //   label: "Clarify this",
      //   value: "clarify",
      //   Icon: ClarifyIcon,
      //   replace: false,
      // },
      {
        label: "Find action items",
        value: "action_items",
        Icon: ActionItemsIcon,
        replace: false,
        mode: MODES.actionItems,
      },
    ],
  },
  {
    group: "",
    groupLabel: "Edit or review page",
    options: [
      {
        label: "Fix spelling & grammar",
        value: "spelling_&_grammar",
        Icon: BookIcon,
        replace: false,
        mode: MODES.grammatical,
      },
      // { ...improveWriting, replace: false },
      ...commonOptions,
      {
        label: "Change tone",
        value: "change_tone",
        Icon: ToneIcon,
        options: toneOptions,
        replace: false,
      },
    ],
  },
];

export const generatedContentOptions = [
  {
    group: "",
    groupLabel: "",
    options: [
      {
        label: "Replace selection",
        value: "replace_selection",
        Icon: CheckIcon,
        replace: true,
      },
      ...commonOptions,
      {
        label: "Try again",
        value: "try_again",
        Icon: TryAgainIcon,
        replace: false,
      },
      {
        label: "Delete",
        value: "close",
        Icon: DeleteIcon,
        replace: false,
      },
    ],
  },
];
