import React, { useEffect, useState } from "react";
import { Popper, Fade, Paper } from "@mui/material";
import { Editor, Range } from "slate";
import { useSlate, useFocused } from "slate-react";
import useDrag from "../../hooks/useDrag";
import { TableUtil } from "../../utils/table";
import useWindowResize from "../../hooks/useWindowResize";
import MiniTextFormat from "./MiniTextFormat";
import { useEditorContext } from "../../hooks/useMouseMove";
import usePopupStyles from "../PopupTool/PopupToolStyle";

const PopupTool = (props) => {
  const { theme, setIsTextSelected, customProps } = props;
  const classes = usePopupStyles(theme);
  const { setPopupType, openAI } = useEditorContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const editor = useSlate();
  const inFocus = useFocused();
  const { selection } = editor;
  const [event] = useDrag(anchorEl);
  const id = open ? "popup-edit-tool" : "";
  const table = new TableUtil(editor);
  const [size] = useWindowResize();

  useEffect(() => {
    if (!inFocus) {
      setAnchorEl(null)
    }
  }, [inFocus])

  useEffect(() => {
    if (event === "end" && anchorEl && !open) {
      // for table cell selection
      const isCellsSelected = table.isCellSelected(editor.selection);
      if (!isCellsSelected) {
        setOpen(true);
        setPopupType("textFormat");
        setIsTextSelected(true);
      }
    } else if (!anchorEl) {
      setOpen(false);
      setPopupType("");
      setIsTextSelected(false);
    }
  }, [event, anchorEl]);

  useEffect(() => {
    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ""
    ) {
      setAnchorEl(null);
    } else {
      updateAnchorEl();
    }
  }, [selection]);

  const updateAnchorEl = () => {
    try {
      const domSelection = window.getSelection();

      const domRange = domSelection?.getRangeAt(0);
      const { startOffset, endOffset } = domRange || {};
      if (startOffset !== endOffset) {
        const rect = domRange.getBoundingClientRect();
        setAnchorEl({
          clientWidth: rect.width,
          clientHeight: rect.height,
          getBoundingClientRect: () => rect,
        });
      }
    } catch (err) {
      console.log(err)
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setPopupType("");
  };


  return open && !openAI ? (
    <>
      {size.device === "xs" ?
        <div className="mobileMiniTextWrapper">
          <MiniTextFormat editor={editor} classes={classes} closeMainPopup={handleClose} customProps={customProps}/>
        </div> : (
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            transition
            placement="auto-end"
            sx={classes.popupWrapper}
            className={`tools-drawer ${size?.device}`}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Paper style={{ borderRadius: "10px" }}>
                  <MiniTextFormat editor={editor} classes={classes} closeMainPopup={handleClose} customProps={customProps} />
                </Paper>
              </Fade>
            )}
          </Popper>
        )}
    </>
  ) : null;
};

export default PopupTool;
