import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LinkSettingsStyles from "./style";
import { getNavOptions } from "./navOptions";
import {
  ScrollTopBottom,
  SelectPage,
  TextInput,
  Trigger,
} from "./NavComponents";
import SwipeableDrawer from "../SwipeableDrawer";

const MAP_COMPONENT = {
  webAddress: TextInput,
  email: TextInput,
  phone: TextInput,
  actionTrigger: Trigger,
  scrollTopOrBottom: ScrollTopBottom,
  page: SelectPage,
};

function getNav(navType, navOptions) {
  return navType
    ? navOptions.find((n) => n.value === navType)
    : {
        label: "None",
        value: "",
      };
}

export default function LinkSettings(props) {
  const { handleClose, onSave, customProps, navType } = props;

  const { isMobile } = customProps;
  const navOptions = getNavOptions(customProps.hideTools);

  const classes = LinkSettingsStyles();
  const [nav, setNav] = useState(getNav(navType, navOptions));

  const [navValue, setNavValue] = useState(props?.navValue || "");
  const [openInNewTab, setOpenInNewTab] = useState(props.openInNewTab || false);

  const NavSettings = MAP_COMPONENT[nav?.value];

  const onChange = (value) => {
    setNavValue(value);
  };

  const onSubmit = () => {
    onSave({
      linkType: nav?.value,
      navValue,
      openInNewTab,
    });
  };

  if (isMobile) {
    return (
      <SwipeableDrawer onClose={handleClose}>
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
          What do you want to link to?
        </Typography>

        <Select
          size="small"
          fullWidth
          value={nav?.value}
          onChange={(e) => {
            const { value } = e.target;

            const selected = getNav(value, navOptions);

            setNav(selected);
            setNavValue("");
          }}
          displayEmpty
        >
          {navOptions.map((navOption, i) => {
            return (
              <MenuItem value={navOption.value} key={i}>
                <Typography variant="body2">{navOption.label}</Typography>
              </MenuItem>
            );
          })}
        </Select>

        <Box sx={{ paddingTop: "14px" }}>
          {NavSettings && (
            <NavSettings
              placeholder={nav?.placeholder}
              nav={nav}
              onChange={onChange}
              value={navValue}
              openInNewTab={openInNewTab}
              onNewTabChange={() => setOpenInNewTab((prev) => !prev)}
              services={customProps.services}
            />
          )}
        </Box>

        <Box component="div" sx={classes.mobileActionBtns}>
          <ActionsButtons
            classes={classes}
            onCancel={handleClose}
            onSave={onSubmit}
          />
        </Box>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        open={true}
        sx={classes.dialogContainer}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>What do you want to link to?</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={classes.gridDivider}>
              <FormControl>
                <RadioGroup value={nav?.value}>
                  {navOptions?.map((navOption, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        value={navOption.value}
                        control={<Radio />}
                        label={navOption.label}
                        onChange={() => {
                          setNav(navOption);
                          setNavValue("");
                        }}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {NavSettings && (
                <NavSettings
                  placeholder={nav?.placeholder}
                  nav={nav}
                  onChange={onChange}
                  value={navValue}
                  openInNewTab={openInNewTab}
                  onNewTabChange={() => setOpenInNewTab((prev) => !prev)}
                  services={customProps.services}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ActionsButtons
            classes={classes}
            onCancel={handleClose}
            onSave={onSubmit}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

function ActionsButtons({ classes, onCancel, onSave }) {
  return (
    <>
      <Button onClick={onCancel} sx={classes.closeBtn}>
        Cancel
      </Button>
      <Button onClick={onSave} sx={classes.saveBtn}>
        Save
      </Button>
    </>
  );
}
