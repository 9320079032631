import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";
import { encodeString } from "./helper";

export const createEmbedScript = (embedData) => ({
  type: "embedScript",
  embedData: encodeString(embedData),
  children: [{ text: " " }],
  isEncoded: true, // to handle the old code's that already inserted
  isSanitized: true, // to handle the old code's that already inserted
});

export const insertEmbedScript = (editor, embedData) => {
  try {
    const embed = createEmbedScript(embedData);
    Transforms.insertNodes(editor, embed, { at: editor.selection.anchor.path });
    insertNewLine(editor);
  } catch (err) {
    console.log(err);
  }
};
