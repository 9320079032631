import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { convertBase64 } from "../../../utils/helper";
import { uploadFile } from "../../../service/fileupload";

const SaveAsTemplate = (props) => {
  const { value, data, customProps, elementProps, handleClose } = props;
  const { onSaveTemplate } = customProps;
  const { key } = data;
  const [template, setTemplate] = useState({
    type: "Element",
    category: "",
    img_aws: null,
    title: "",
  });
  const [base64, setBase64] = useState(value);
  const [uploading, setUploading] = useState(false);

  const handleChange = async (e) => {
    const file = e.target.files[0];
    const strImage = await convertBase64(file);
    setBase64(strImage);
    doUpload(e.target.files[0]);
  };

  const onChange = (e) => {
    setTemplate({
      ...template,
      [e.target.name]: e.target.value,
    });
  };

  const doUpload = async (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.set("file", file);
    formData.set("resource_type", "pages");
    formData.set("resource_id", customProps?.page_id);
    const result = await uploadFile(formData, customProps);
    setUploading(false);
    if (result && result?.imageURL) {
      setTemplate({
        ...template,
        img_aws: result?.imageURL,
      });
    }
  };

  const onRemoveBG = () => {
    setBase64(null);
    onChange({
      [key]: "none",
    });
  };

  const handleSave = () => {
    const formData = new FormData();
    const params = {
      ...template,
      thumbnail: null,
      content: JSON.stringify(elementProps),
    };
    for (let key in params) {
      formData.append(key, params[key]);
    }
    onSaveTemplate(formData, params);
    handleClose();
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField
          name={"title"}
          value={template?.title}
          onChange={onChange}
          placeholder="Template Title"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <Select
          fullWidth
          value={template?.type}
          name="type"
          onChange={onChange}
          size="small"
        >
          <MenuItem value={"Element"}>
            <Typography
              variant="body1"
              color={"primary"}
              sx={{ fontSize: "14px" }}
            >
              Element
            </Typography>
          </MenuItem>
          <MenuItem value={"Section"}>
            <Typography
              variant="body1"
              color={"primary"}
              sx={{ fontSize: "14px" }}
            >
              Section
            </Typography>
          </MenuItem>
          <MenuItem value={"Templates"}>
            <Typography
              variant="body1"
              color={"primary"}
              sx={{ fontSize: "14px" }}
            >
              Templates
            </Typography>
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="category"
          placeholder="Category"
          value={template?.category}
          onChange={onChange}
          size="small"
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <input
            type="file"
            style={{ opacity: 0, width: "0px" }}
            onChange={handleChange}
          />
        </Button>
        <Grid className="dflex">
          <input
            type="checkbox"
            value={"none"}
            checked={value === "none"}
            onChange={onRemoveBG}
          />{" "}
          <Typography variant="body1" color={"primary"}>
            Remove
          </Typography>
        </Grid>
      </Grid>
      {uploading ? "Uploading..." : ""}
      <Grid
        item
        xs={12}
        style={{
          marginTop: "12px",
          width: "100%",
          backgroundImage: base64 ? `url(${base64})` : "none",
          height: "100px",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleSave} sx={{ fontWeight: 700 }}>
          Save As Template
        </Button>
      </Grid>
    </>
  );
};

export default SaveAsTemplate;
