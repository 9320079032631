import { Box, IconButton, TextareaAutosize, Typography } from "@mui/material";
import Styles from "./Styles";
import Icon from "../../common/Icon";
// import { TbRotateClockwise } from "react-icons/tb";
// import { FaRegCircleCheck } from "react-icons/fa6";
import { IoSend } from "react-icons/io5";
import React, { forwardRef, useEffect, useRef } from "react";
import WaveLoading from "../../common/WaveLoading";
import useWindowResize from "../../hooks/useWindowResize";
import CustomSelect from "./CustomSelect";
import {
  editContentOptions,
  newContentOptions,
  generatedContentOptions,
} from "./helper";
import useClickOutside from "../../hooks/useClickOutside";
import { useEditorContext } from "../../hooks/useMouseMove";

function getProps(openAI, generatedText) {
  const dropDownProps = {
    fromElements: {
      options: newContentOptions,
      show: window.getSelection().toString().length,
    },
    fromToolBar: {
      options: editContentOptions,
      show: true,
    },
    generatedContent: {
      options: generatedContentOptions,
      show: true,
    },
  };

  if (openAI === "fromToolBar" && generatedText?.length) {
    return dropDownProps.generatedContent;
  }

  return dropDownProps[openAI];
}

function AIInput(
  {
    onSend,
    loading,
    generatedText,
    openAI,
    inputValue,
    onInputChange,
    onClickOutside,
  },
  ref
) {
  const { theme } = useEditorContext();
  const classes = Styles(theme);
  const inputRef = useRef();
  const [size] = useWindowResize();
  const inputWrapperRef = useRef();

  const refs = useClickOutside({
    onClickOutside,
    omitIds: ["infinity-select-popover"],
    omitClass: ["MuiModal-backdrop"],
    refCount: 2,
  });

  useEffect(() => {
    let timeoutId;
    if (inputRef.current && openAI !== "fromToolBar") {
      timeoutId = setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [openAI]);

  const isSendBtnDisabled = !inputValue || loading;

  const props = getProps(openAI, generatedText) || {};

  const fromToolBar = openAI === "fromToolBar";

  const handleSendBtnClick = () => {
    if (isSendBtnDisabled) {
      return;
    }

    onSend("", { replace: true, isSendBtn: true });
  };

  return (
    <>
      {size.device === "xs" ? (
        <Box component="div" ref={refs[1]} sx={classes.customSelectWrapper}>
          <CustomSelect {...props} onSend={onSend} classes={classes} />
        </Box>
      ) : null}

      <Box component={"div"} sx={classes.aiContainer}>
        {generatedText ? (
          <Typography sx={classes.generatedText}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                fontFamily: "inherit",
                margin: 0,
              }}
            >
              {generatedText}
            </pre>
          </Typography>
        ) : null}

        <Box
          component="form"
          sx={classes.aiInputWrapper}
          onSubmit={(e) => {
            e.preventDefault();
          }}
          ref={refs[0]}
        >
          <div className="icon-container icons-elements" ref={inputWrapperRef}>
            <Icon icon="infinityIcon" />
          </div>

          {loading ? (
            <div className="loading-container">
              <WaveLoading />
            </div>
          ) : (
            <TextareaAutosize
              className="ai-input"
              placeholder={fromToolBar ? "" : "Ask AI to write anything..."}
              ref={inputRef}
              value={inputValue}
              onChange={onInputChange}
              disabled={fromToolBar}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleSendBtnClick();
                }
              }}
            />
          )}

          {fromToolBar ? null : (
            <Box
              component="div"
              style={classes.sendIconContainer}
              className="icons-elements"
            >
              <IconButton
                sx={
                  isSendBtnDisabled ? classes.sendBtnDisabled : classes.sendBtn
                }
                onClick={() => handleSendBtnClick()}
              >
                <IoSend color="#fff" size={14} />
              </IconButton>
            </Box>
          )}

          {/* <div className="action-btns">
        <Button className="btn-wrapper">
          <TbRotateClockwise color="#64748B" /> <Typography>Retry</Typography>
        </Button>
        <Button className="btn-wrapper">
          <FaRegCircleCheck color="#64748B" /> <Typography>Done</Typography>
        </Button>
      </div> */}
        </Box>
      </Box>

      {size.device === "xs" ? null : (
        <Box component="div" ref={refs[1]} sx={classes.customSelectWrapper}>
          <CustomSelect {...props} onSend={onSend} classes={classes} />
        </Box>
      )}
    </>
  );
}

export default forwardRef(AIInput);
