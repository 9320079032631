import { insertAccordion } from "../../utils/accordion";
import { insertButton } from "../../utils/button";
import { insertCarousel } from "../../utils/carousel";
import { insertDefaultEmbed } from "../../utils/embed";
import { insertForm } from "../../utils/form";
import { insertGrid } from "../../utils/grid";
import { insertSignature } from "../../utils/signature";
import { toggleBlock } from "../../utils/SlateUtilityFunctions";
import { TableUtil } from "../../utils/table";
import Icon from "../Icon";
import EmojiButton from "../../Elements/Emoji/EmojiButton";
import { insertDivider } from "../../utils/divider";
import { Transforms } from "slate";
import { insertInfinityAI } from "../../utils/infinityAI";

const ELEMENTS_LIST = [
  {
    name: "Heading 1",
    desc: "",
    group: "Text",
    type: "headingOne",
    icon: <Icon icon="headingOne" />,
    onInsert: (editor) => toggleBlock(editor, "headingOne", false),
  },
  {
    name: "Heading 2",
    desc: "",
    group: "Text",
    type: "headingTwo",
    icon: <Icon icon="headingTwo" />,
    onInsert: (editor) => toggleBlock(editor, "headingTwo", false),
  },
  {
    name: "Heading 3",
    desc: "",
    group: "Text",
    type: "headingThree",
    icon: <Icon icon="headingThree" />,
    onInsert: (editor) => toggleBlock(editor, "headingThree", false),
  },
  {
    name: "Quote",
    desc: "",
    group: "Text",
    type: "blockquote",
    icon: <Icon icon="blockquote" />,
    onInsert: (editor) =>
      toggleBlock(editor, "blockquote", false, { color: "rgb(47, 94, 188)" }),
  },
  {
    name: "Colorbox",
    desc: "",
    group: "Text",
    type: "colorbox",
    icon: <Icon icon="colorbox" />,
    onInsert: (editor) =>
      toggleBlock(editor, "blockquote", false, {
        bgColor: "rgb(227, 236, 255)",
      }),
  },
  {
    name: "Ordered List",
    desc: "",
    group: "List",
    type: "orderedList",
    icon: <Icon icon={"orderedList"} />,
    onInsert: (editor) => toggleBlock(editor, "orderedList", false),
  },
  {
    name: "Bulleted List",
    desc: "",
    group: "List",
    type: "unorderedList",
    icon: <Icon icon={"unorderedList"} />,
    onInsert: (editor) => toggleBlock(editor, "unorderedList", false),
  },
  {
    name: "Check List",
    desc: "",
    group: "List",
    type: "check-list-item",
    icon: <Icon icon={"check-list-item"} />,
    onInsert: (editor) => toggleBlock(editor, "check-list-item", false),
  },
  {
    name: "Image",
    desc: "",
    group: "Media",
    type: "embed",
    icon: <Icon icon={"image"} />,
    onInsert: (editor) => insertDefaultEmbed(editor, "image"),
  },
  {
    name: "Video",
    desc: "",
    group: "Media",
    type: "embed",
    icon: <Icon icon={"video"} />,
    onInsert: (editor) => insertDefaultEmbed(editor, "video"),
  },
  {
    name: "Embed",
    desc: "",
    group: "Media",
    type: "embed",
    icon: <Icon icon={"embed"} />,
    onInsert: (editor) => insertDefaultEmbed(editor, "embed", ""),
  },
  {
    name: "Calendly",
    desc: "",
    group: "Media",
    type: "embed",
    icon: <Icon icon={"calenderNewIcon"} />,
    onInsert: (editor) => insertDefaultEmbed(editor, "calendly", ""),
  },
  {
    name: "Emoji",
    group: "Elements",
    desc: "",
    type: "emoji",
    renderComponent: (rest) => <EmojiButton {...rest} icoBtnType={"cmd"} />,
  },
  {
    name: "Table",
    group: "Elements",
    desc: "",
    type: "table",
    icon: <Icon icon={"table"} />,
    onInsert: (editor) => {
      const table = new TableUtil(editor);
      table.insertTable(3, 3);
    },
  },
  {
    name: "Divider",
    group: "Elements",
    desc: "",
    type: "divider",
    icon: <Icon icon={"divider"} />,
    onInsert: (editor) => {
      insertDivider(editor);
    },
  },
  {
    name: "Grid",
    group: "Elements",
    desc: "",
    type: "grid",
    icon: <Icon icon={"grid"} />,
    onInsert: (editor) => {
      insertGrid(editor);
    },
  },
  {
    name: "Accordion",
    group: "Elements",
    desc: "",
    type: "accordion",
    icon: <Icon icon={"accordion"} />,
    onInsert: (editor) => {
      Transforms.delete(editor, editor.selection); // remove text '/accordion' typed by user
      insertAccordion(editor);
    },
  },
  {
    name: "Button",
    group: "Elements",
    desc: "",
    type: "button",
    icon: <Icon icon={"button"} />,
    onInsert: (editor) => {
      insertButton(editor);
    },
  },
  {
    name: "Signature",
    group: "Elements",
    desc: "",
    type: "signature",
    icon: <Icon icon={"signature"} />,
    onInsert: (editor) => {
      insertSignature(editor);
    },
  },
  {
    name: "Carousel",
    group: "Elements",
    desc: "",
    type: "carousel",
    icon: <Icon icon={"carousel"} />,
    onInsert: (editor) => {
      insertCarousel(editor);
    },
  },
  {
    name: "Form",
    group: "Elements",
    desc: "",
    type: "form",
    icon: <Icon icon={"form"} />,
    onInsert: (editor) => {
      insertForm(editor);
    },
  },
  {
    name: "Ask AI to write anything",
    group: "Infinity AI",
    desc: "",
    type: "infinityAI",
    icon: <Icon icon={"infinityIcon"} />,
    onInsert: (editor) => {
      insertInfinityAI(editor);
    },
  },
];

const elements = (props) => {
  const { search, hideTools } = props;
  const filteredElements = ELEMENTS_LIST.filter(
    (f) => (hideTools || []).indexOf(f.type) === -1
  );
  return filteredElements.filter((c) =>
    c.name.toLowerCase().includes(search?.toLowerCase())
  );
};

export default elements;
