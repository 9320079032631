import { Box, SwipeableDrawer } from "@mui/material";
import DrawerStyles from "./style";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme?.palette?.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  margin: "auto",
  marginTop: "14px",
}));

function SwipeableDrawerComponent({ open, onClose, children }) {
  const classes = DrawerStyles();

  return (
      <SwipeableDrawer
        anchor="bottom"
        open={true}
        onClose={onClose}
        sx={classes.drawerContainer}
      >
        <Puller />

        <Box sx={classes.childContainer}>
         {children}
        </Box>
      </SwipeableDrawer>
  );
}

export default SwipeableDrawerComponent;
