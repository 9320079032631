import React from "react";
import {
  MdFormatQuote,
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdFormatListNumbered,
  MdFormatListBulleted,
  MdAdd,
  MdArrowForward,
  MdEmojiEmotions,
  MdOutlinePermMedia,
} from "react-icons/md";
import {
  BsTypeH1,
  BsTypeH2,
  BsTypeH3,
  BsCameraVideoFill,
  BsArrowBarRight,
  BsArrowBarLeft,
  BsFillMenuButtonWideFill,
  BsCodeSlash,
} from "react-icons/bs";
import { FaSuperscript, FaSubscript } from "react-icons/fa";
import { FcAddRow, FcAddColumn } from "react-icons/fc";
import {
  AiFillEdit,
  AiOutlineInsertRowBelow,
  AiOutlineInsertRowRight,
  AiOutlineDelete,
  AiFillTag,
  AiOutlineUpload,
  AiOutlineArrowsAlt,
  AiOutlineInsertRowAbove,
  AiOutlineInsertRowLeft,
  AiFillHtml5,
} from "react-icons/ai";
import { SiLatex } from "react-icons/si";
import { RiDeleteColumn, RiDeleteRow } from "react-icons/ri";
import { IoIosImage } from "react-icons/io";
import {
  GridIcon,
  AccordionIcon,
  SignatureIcon,
  ButtonIcon,
  Carousal,
  FormIcon,
  BoldIcon,
  FontFamilyIcon,
  FontSizeIcon,
  ImageIcon,
  ItalicIcon,
  LinkIcon,
  StrikethroughIcon,
  TableIcon,
  UnderLineIcon,
  VideoIcon,
  CheckboxIcon,
  AppHeader,
  MoreHorizontal,
  UploadImage,
  DocsUpload,
  LeftArrow,
  RightArrow,
  CheckListButton,
  CheckListButtonActive,
  ExcelIcon,
  CsvIcon,
  DividerIcon,
  CloseIcon,
  SearchIcon,
  ExpandIcon,
  CalendarIconNew,
  Text,
  TextAreaIcon,
  Phone,
  BriefCase,
  Bank,
  CalendarTick,
  DollarSquare,
  Checkbox,
  Description,
  RadioButtonIcon,
  CheckedIcon,
  UncheckedIcon,
  InfinityIcon,
  ResetIcon,
} from "./iconslist";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const iconList = {
  fontFamily: <FontFamilyIcon size={20} />,
  fontSize: <FontSizeIcon size={20} />,
  // bold: <MdFormatBold size={20} />,
  bold: <BoldIcon size={20} />,
  // italic: <MdFormatItalic size={20} />,
  italic: <ItalicIcon size={20} />,
  // strikethrough: <MdStrikethroughS size={20} />,
  strikethrough: <StrikethroughIcon size={20} />,
  // underline: <MdFormatUnderlined size={20} />,
  underline: <UnderLineIcon size={20} />,
  headingOne: <BsTypeH1 size={18} fill="#64748B" />,
  headingTwo: <BsTypeH2 size={18} fill="#64748B" />,
  headingThree: <BsTypeH3 size={18} fill="#64748B" />,
  blockquote: <MdFormatQuote size={20} fill="#64748B" />,
  doublequote: <MdFormatQuote size={20} fill="#64748B" />,
  superscript: <FaSuperscript size={15} fill="#64748B" />,
  subscript: <FaSubscript size={15} fill="#64748B" />,
  alignLeft: <MdFormatAlignLeft size={18} fill="#64748B" />,
  alignCenter: <MdFormatAlignCenter size={18} fill="#64748B" />,
  alignRight: <MdFormatAlignRight size={18} fill="#64748B" />,
  orderedList: <MdFormatListNumbered size={18} fill="#64748B" />,
  unorderedList: <MdFormatListBulleted size={18} fill="#64748B" />,
  // link: <MdInsertLink size={20} />,
  link: <LinkIcon size={20} />,
  // image: <MdImage size={20} />,
  image: <ImageIcon size={20} />,
  // video: <MdVideoLibrary size={20} />,
  video: <VideoIcon size={20} />,
  add: <MdAdd size={20} />,
  // table: <AiOutlineTable size={20} />,
  table: <TableIcon size={20} />,
  insertRowBelow: <AiOutlineInsertRowBelow size={25} />,
  insertColumnRight: <AiOutlineInsertRowRight size={25} />,
  insertColumnLeft: <AiOutlineInsertRowLeft size={25} />,
  insertRowAbove: <AiOutlineInsertRowAbove size={25} />,
  trashCan: <AiOutlineDelete size={25} />,
  addId: <AiFillTag size={20} />,
  upload: <AiOutlineUpload size={20} />,
  equation: <SiLatex size={20} />,
  resize: <AiOutlineArrowsAlt size={20} />,
  videoPlayer: <BsCameraVideoFill size={20} />,
  insertHtml: <AiFillHtml5 size={20} />,
  arrowRight: <MdArrowForward size={35} />,
  pen: <AiFillEdit size={20} />,
  emoji: <MdEmojiEmotions size={20} fill="#64748B" />,
  grid: <GridIcon />,
  accordion: <AccordionIcon />,
  signature: <SignatureIcon />,
  button: <ButtonIcon />,
  carousel: <Carousal />,
  form: <FormIcon />,
  tableCellResizeLeft: <BsArrowBarRight size={20} />,
  tableCellResizeRight: <BsArrowBarLeft size={20} />,
  deleteColumn: <RiDeleteColumn size={20} />,
  addColumn: <FcAddColumn size={20} />,
  deleteRow: <RiDeleteRow size={20} />,
  addRow: <FcAddRow size={20} />,
  "check-list-item": <CheckboxIcon size={20} />,
  embed: <LinkIcon />,
  topbanner: <IoIosImage size={20} fill="#64748B" />,
  fileUpload: <UploadImage />,
  media: <MdOutlinePermMedia size={20} />,
  accordionArrow: <ArrowRightIcon size={20} />,
  accordionArrowDown: <ArrowDropDownIcon size={20} />,
  appHeader: <AppHeader />,
  moreHorizantal: <MoreHorizontal />,
  docsUpload: <DocsUpload />,
  colorbox: <BsFillMenuButtonWideFill style={{ fill: "#64748B" }} size={20} />,
  leftArrow: <LeftArrow />,
  rightArrow: <RightArrow />,
  checkListButton: <CheckListButton />,
  checkListButtonActive: <CheckListButtonActive />,
  excelIcon: <ExcelIcon />,
  csvIcon: <CsvIcon />,
  divider: <DividerIcon />,
  SearchIcon: <SearchIcon />,
  expandIcon: <ExpandIcon />,
  closeIcon: <CloseIcon />,
  embedScript: <BsCodeSlash size={20} style={{ fill: "#64748B" }} />,
  calenderNewIcon: <CalendarIconNew />,
  text: <Text />,
  textArea: <TextAreaIcon/>,
  phone: <Phone />,
  briefCase: <BriefCase />,
  bank: <Bank />,
  calendarTick: <CalendarTick />,
  dollarSquare: <DollarSquare />,
  description: <Description />,
  radioButton: <RadioButtonIcon />,
  checkbox: <Checkbox />,
  mail: <EmailRoundedIcon sx={{width: "18px", height: "16px", fill: "#000"}} />,
  info: <InfoOutlinedIcon sx={{ width: "14px", height: "14px", paddingLeft: "5px", marginTop: "5px", fill: "#94A3B8" }} />,
  checkedIcon: <CheckedIcon />,
  uncheckedIcon: <UncheckedIcon />,
  infinityIcon: <InfinityIcon />,
  resetIcon: <ResetIcon />
};

const Icon = (props) => {
  const { icon } = props;
  return iconList[icon] ? iconList[icon] : <div>dummy {icon}</div>;
};

export default Icon;
