/* eslint-disable no-unused-vars */
import React, {
  useRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { createEditor, Transforms } from "slate";
import { Slate, Editable, ReactEditor } from "slate-react";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { getMarked, getBlock } from "./utils/SlateUtilityFunctions";
import CodeToText from "./Elements/CodeToText/CodeToText";
import { draftToSlate } from "./utils/draftToSlate";
import useMentions from "./hooks/useMentions";
import MentionsPopup from "./common/MentionsPopup";
import { RemoteCursorOverlay } from "./RemoteCursorOverlay/Overlay";
import {
  mentionsEvent,
  commands,
  indentation,
  escapeEvent,
  enterEvent,
} from "./utils/events";
import withCommon from "./hooks/withCommon";
import DialogWrapper from "./DialogWrapper";
import { serializeToText } from "./utils/serializeToText";
import { getPageSettings } from "./utils/pageSettings";
import { getThumbnailImage, invertColor } from "./helper";
import PopupTool from "./Toolbar/PopupTool";
import "./font.css";
import "./Editor.css";
import { Box, Button, Typography } from "@mui/material";
import Shorthands from "./common/Shorthands";
import MiniToolbar from "./Toolbar/Mini/MiniToolbar";
import { EditorProvider } from "./hooks/useMouseMove";
import TopBanner from "./Elements/TopBanner/TopBanner";
import editorStyles from "./Styles/EditorStyles";
import DragAndDrop from "./common/DnD";
import Section from "./common/Section";
import "animate.css";
import decorators from "./utils/Decorators";
import { getTRBLBreakPoints } from "./helper/theme";
import {
  handleInsertLastElement,
  outsideEditorClickLabel,
} from "./utils/helper";
import useWindowResize from "./hooks/useWindowResize";
import PopoverAIInput from "./Elements/AI/PopoverAIInput";

const Item = forwardRef(({ children, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      contentEditable={false}
      style={{ background: "rgba(255, 255,255, 0.54)" }}
    >
      {children}
    </div>
  );
});
Item.displayName = "Item";

const Element = (props) => {
  return <Section {...props}>{getBlock(props)}</Section>;
};

const Leaf = ({ attributes, children, leaf }) => {
  children = getMarked(leaf, children);
  return <span {...attributes}>{children}</span>;
};

const CommonEditor = forwardRef((props, ref) => {
  const {
    id,
    agency_id,
    site_id,
    page_title,
    content,
    onSave,
    editor: collaborativeEditor,
    readOnly,
    toolbarOptions,
    otherProps,
    isIframe,
    theme,
  } = props;
  const editorWrapper = useRef();
  const mentionsRef = useRef();
  const convertedContent = draftToSlate({ data: content });
  const [value, setValue] = useState(convertedContent);
  const [loadedValue] = useState(value);
  const [isInteracted, setIsInteracted] = useState(false);
  const [deboundedValue] = useDebounce(value, 500);
  const [fullScreen, setFullScreen] = useState(false);
  const [toolbarShow, setToolbarShow] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [viewport, setViewport] = useState({ w: null, h: null });
  const [isScrolling, setIsScrolling] = useState(false);
  const [isTextSelected, setIsTextSelected] = useState(false);

  const [size] = useWindowResize();
  const {
    needDotsBG,
    footer,
    needLayout = true,
    CHARACTERS = [],
    editorClass,
    fixedWidth = "60%",
    fullWidth = "80%",
    hideTools,
  } = otherProps || {};
  const [drag, setDrag] = useState(null);
  const editor = useMemo(() => {
    if (collaborativeEditor) return collaborativeEditor;
    return withCommon(createEditor(), { needLayout });
  }, [collaborativeEditor]);

  const { element: pageSt } = getPageSettings(editor) || {};
  const {
    bannerSpacing,
    pageBgImage,
    pageColor,
    color: pageTextColor,
    pageWidth,
    maxWidth: pageMaxWidth,
  } = pageSt?.pageProps || {
    bannerSpacing: { left: 0, right: 0, top: 0, bottom: 0 },
  };
  const classes = editorStyles({
    padHeight: !fullScreen ? otherProps?.padHeight : 20,
    placeHolderColor: invertColor(pageColor || "#FFF"),
    theme,
  });

  useEffect(() => {
    setValue(draftToSlate({ data: content }));
  }, [id, content]);

  useEffect(() => {
    if (
      editorWrapper &&
      editorWrapper?.current &&
      JSON.stringify(loadedValue) !== JSON.stringify(deboundedValue) &&
      isInteracted &&
      onSave
    ) {
      const { value: strVal, ...restVal } = getOnSaveData(deboundedValue);
      onSave(strVal, restVal);
    }
  }, [deboundedValue]);

  const getOnSaveData = (val) => {
    const text = serializeToText(val);
    const title = val?.find((f) => f.type === "title");
    return {
      value: JSON.stringify(val),
      text: text,
      title: serializeToText(title?.children) || "Untitled",
    };
  };

  const getPreviewImage = async (needBackground = false, options = {}) => {
    ReactEditor.blur(editor);
    const dom = needBackground
      ? editorWrapper?.current
      : editorWrapper?.current.getElementsByClassName(
          "innert-editor-textbox"
        )[0];
    const c = await getThumbnailImage(dom, {
      ...options,
      proxy: `${otherProps?.PAGES_API_HOST}/page/2canvas`,
    });
    return c;
  };

  useImperativeHandle(ref, () => ({
    async getThumbnail(needBackground = false, options = {}) {
      try {
        const c = await getPreviewImage(needBackground, options);
        return c;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    getEditor() {
      return editor;
    },

    getContent() {
      return getOnSaveData(deboundedValue);
    },

    insertFragments(fragments, clearAll = false, rest = {}) {
      try {
        if (!clearAll) {
          if (rest?.nextLine) {
            const { anchor } = editor?.selection || {};
            if (anchor?.path !== undefined && anchor?.path[0] !== undefined) {
              editor.insertNode(fragments, { at: [anchor?.path[0] + 1] });
            }
          } else {
            editor.insertNode(fragments);
          }
        } else {
          // loop delete all
          editor.children.forEach(() => {
            Transforms.delete(editor, { at: [0] });
          });

          // reset init
          editor.children = fragments;
        }
      } catch (err) {
        console.log(err);
      }
    },

    toggleFullscreen() {
      setFullScreen(!fullScreen);
    },

    toggleToolbarShow() {
      setToolbarShow(!toolbarShow);
    },

    changeViewport({ w, h }) {
      setViewport({ w, h });
    },

    undo() {
      editor?.undo();
    },

    redo() {
      editor?.redo();
    },

    getPageSettings: {
      background:
        pageBgImage && pageBgImage !== "none"
          ? `url(${pageBgImage})`
          : pageColor || "",
    },
  }));

  const [htmlAction, setHtmlAction] = useState({
    showInput: false,
    html: "",
    action: "",
    location: "",
  });
  const [mentions, setMentions] = useMentions({
    editor,
    selection: editor?.selection,
  });

  const { search, target, index, type } = mentions;
  const chars = type
    ? Shorthands[type]({ ...mentions, CHARACTERS, hideTools: hideTools || [] })
    : [];

  const handleEditorChange = (newValue) => {
    setValue(newValue);
    if (!isInteracted) {
      setIsInteracted(true);
    }
  };

  const onDrawerOpen = (status) => {
    setIsDrawerOpen(status);
  };

  const isReadOnly = readOnly === "readonly";
  const customProps = {
    ...(otherProps || {}),
    readOnly: isReadOnly,
    page_id: id,
    agency_id: agency_id,
    site_id: site_id,
    page_title: page_title,
    isIframe: isIframe,
    onDrawerOpen: onDrawerOpen,
  };
  const renderElement = useCallback((props) => {
    return <Element {...props} customProps={customProps} />;
  }, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} customProps={customProps} />;
  }, []);

  const handleCodeToText = (partialState) => {
    setHtmlAction((prev) => ({
      ...prev,
      ...partialState,
    }));
  };

  const onKeyDown = useCallback(
    (event) => {
      const isMetaKey =
        event.metaKey && event.keyCode >= 65 && event.keyCode <= 90;
      const isCtrlKey = event.ctrlKey || isMetaKey;
      if (target && chars.length > 0 && !isCtrlKey) {
        mentionsEvent({
          event,
          mentions,
          setMentions,
          chars,
          target,
          editor,
          type,
          mentionsRef,
        });
      } else if (isCtrlKey) {
        commands({
          event,
          editor,
        });
      } else if (event.key === "Tab") {
        event.preventDefault();
        indentation({ editor });
      } else if (event.key === "Escape") {
        event.preventDefault();
        escapeEvent({ editor });
      } else if (event.key === "Enter") {
        enterEvent(event, editor, customProps?.isMobile);
      }
    },
    [chars, editor, target, mentions, setMentions, search, type, mentionsRef]
  );

  const Overlay =
    collaborativeEditor && !isReadOnly ? RemoteCursorOverlay : React.Fragment;

  const dotBg = needDotsBG
    ? {
        background: "white",
        backgroundImage: "radial-gradient(#CCC 2px, transparent 0)",
        backgroundSize: "40px 40px",
        backgroundPosition: "-19px -19px",
      }
    : {};

  const handleScrollStop = useDebouncedCallback(() => {
    setIsScrolling(false);
  }, 200);

  const handleScroll = () => {
    setIsScrolling(true);
    handleScrollStop();
  };

  const hasTopBanner = () => {
    const tb = editor.children[0];
    return tb?.type === "topbanner" ? tb : null;
  };

  const renderTopBanner = () => {
    const tb = hasTopBanner();
    return tb ? (
      <TopBanner element={tb} editor={editor} customProps={customProps} />
    ) : null;
  };

  const hideMiniToolBar = useMemo(() => {
    if (readOnly) {
      return true;
    }

    if (size?.device === "xs" && isTextSelected) {
      return true;
    }
  }, [readOnly, isTextSelected]);

  const handleFooterClick = () => {
    window.open("https://www.flozy.com", "_blank");
  };

  const editorWrapperStyle = useMemo(() => {
    const style = {
      color: pageTextColor || "",
    };

    if (pageBgImage && pageBgImage !== "none") {
      style.backgroundImage = `url(${pageBgImage})`;
      style.backgroundSize = `cover`;
      style.backgroundRepeat = "repeat";
    } else {
      style.background = pageColor || "";
    }

    return style;
  }, [pageBgImage, pageColor]);

  return (
    <EditorProvider theme={theme} editor={editor}>
      <DialogWrapper
        classes={classes}
        {...props}
        fullScreen={fullScreen}
        footer={footer || ""}
      >
        <Box
          component={"div"}
          className={`${editorClass || ""} ${isIframe ? "iframe-editor" : ""}`}
          sx={classes.root}
          style={{
            ...dotBg,
          }}
        >
          <Slate
            key={id}
            editor={editor}
            initialValue={value}
            onChange={handleEditorChange}
          >
            <DragAndDrop>
              <Overlay>
                <Box
                  className={`${hasTopBanner() ? "has-topbanner" : ""} ${
                    !pageColor ? "no-color" : ""
                  } ${
                    isScrolling ? "" : "hideScroll"
                  } scrollable-content scrollSmooth`}
                  sx={classes.slateWrapper}
                  id="slate-wrapper-scroll-container"
                  // style={editorWrapperStyle}
                  ref={editorWrapper}
                  onClick={(e) => {
                    handleInsertLastElement(e, editor);
                  }}
                  onScroll={handleScroll}
                  style={editorWrapperStyle}
                >
                  <Box
                    component={"div"}
                    className="max-content"
                    data-info={outsideEditorClickLabel}
                  >
                    {renderTopBanner()}
                    <div
                      className="scroll-area"
                      data-info={outsideEditorClickLabel}
                    >
                      <Box
                        component={"div"}
                        className={`editor-wrapper ${
                          pageWidth === "fixed" ? "fixed" : "full"
                        }`}
                        sx={{
                          backgroundColor: "transparent",
                          padding: {
                            ...getTRBLBreakPoints(bannerSpacing),
                          },
                          width:
                            !pageWidth || pageWidth === "fixed"
                              ? fixedWidth
                              : fullWidth,
                          height: viewport.h ? `${viewport.h}px` : `100%`,
                          alignSelf: "center",
                          transformOrigin: "left top",
                          transition: "all 0.3s",
                          minHeight: "87%",
                          maxWidth: pageMaxWidth
                            ? `${parseInt(pageMaxWidth)}px !important`
                            : "auto",
                        }}
                        data-info={outsideEditorClickLabel}
                      >
                        <Editable
                          className="innert-editor-textbox"
                          readOnly={isReadOnly}
                          renderElement={renderElement}
                          renderLeaf={renderLeaf}
                          decorate={decorators}
                          onKeyDown={onKeyDown}
                        />
                        {!readOnly ? (
                          <MentionsPopup
                            ref={mentionsRef}
                            mentions={mentions}
                            setMentions={setMentions}
                            editor={editor}
                            target={target}
                            index={index}
                            chars={chars}
                            type={type}
                            theme={theme}
                          />
                        ) : null}
                      </Box>
                    </div>
                    {!hideMiniToolBar ? (
                      <MiniToolbar
                        customProps={customProps}
                        toolbarOptions={toolbarOptions}
                        theme={theme}
                      />
                    ) : null}

                    <PopoverAIInput otherProps={otherProps || {}} />

                    {footer && (fullScreen || readOnly) && (
                      <Typography
                        sx={{
                          color: "rgb(100, 116, 139)",
                          fontSize: "13px",
                          paddingBottom: hideMiniToolBar ? "0px" : "12px",
                          cursor: "pointer",
                        }}
                        align="center"
                        data-info={outsideEditorClickLabel}
                        onClick={handleFooterClick}
                      >
                        {footer}
                      </Typography>
                    )}
                  </Box>
                </Box>

                {!readOnly ? (
                  <PopupTool
                    onDrawerOpen={onDrawerOpen}
                    theme={theme}
                    setIsTextSelected={setIsTextSelected}
                    customProps={customProps}
                  />
                ) : null}
              </Overlay>
            </DragAndDrop>
            {htmlAction.showInput && (
              <CodeToText {...htmlAction} handleCodeToText={handleCodeToText} />
            )}
          </Slate>
        </Box>
      </DialogWrapper>
    </EditorProvider>
  );
});
CommonEditor.displayName = "CommonEditor";

export default CommonEditor;
