import { useEffect, useRef, useState } from "react";
import { useEditorContext } from "../../hooks/useMouseMove";
import Styles from "./Styles";
import { Box, Fade, Paper, Popper } from "@mui/material";
import AIInput from "./AIInput";
import { ReactEditor, useSlate } from "slate-react";
import { Editor, Node, Path, Transforms } from "slate";
import useWindowResize from "../../hooks/useWindowResize";
import { MODES } from "./helper";
import { getSelectedText } from "../../utils/helper";

const scrollToAIInput = () => {
  try {
    setTimeout(() => {
      const slateWrapper = document.getElementById(
        "slate-wrapper-scroll-container"
      );

      const selectionRect = window
        .getSelection()
        .getRangeAt(0)
        .getBoundingClientRect();

      const halfOfWrapper = slateWrapper.clientHeight / 2;

      const selectionScollTop = selectionRect.y + selectionRect.height;
      if (selectionScollTop > halfOfWrapper) {
        // scroll to half of the slateWrapper
        slateWrapper.scrollTo({
          top: slateWrapper.scrollTop + selectionScollTop - halfOfWrapper,
          behavior: "smooth",
        });
      }
    }, 200);
  } catch (err) {
    console.log(err);
  }
};

const insertAtNextLine = (editor, text) => {
  const nextLine = getNextLine(editor);

  Transforms.splitNodes(editor, { at: nextLine.at });

  Transforms.insertNodes(
    editor,
    { type: "paragraph", children: [{ text }] },
    { at: nextLine.at }
  );

  const currentPath = Path.parent(nextLine.at.focus.path);
  const nextPath = Path.next(currentPath);

  ReactEditor.focus(editor);
  Transforms.select(editor, {
    anchor: Editor.start(editor, nextPath),
    focus: Editor.end(editor, nextPath),
  });
};

const getNextLine = (editor) => {
  const { selection } = editor;
  const { focus } = selection;
  const { text = "" } = Node.get(editor, focus.path);

  let nextLineIndex = 0;
  let indexOfNextLine = 0;

  if (text?.length) {
    // split the text based on caret position
    const textBeforeCaret = text.substring(0, focus.offset);
    const textAfterCaret = text.substring(focus.offset);

    // getting the index of the next line after the caret position
    indexOfNextLine = textAfterCaret?.indexOf("\n");

    if (indexOfNextLine >= 0) {
      // index of next line
      nextLineIndex = textBeforeCaret?.length + indexOfNextLine;
    } else {
      nextLineIndex = text?.length;
    }
  }

  const data = {
    ...focus,
    offset: nextLineIndex,
  };

  const at = {
    anchor: data,
    focus: data,
  };

  return { at, indexOfNextLine };
};

const updateAnchorEl = (setAnchorEl, editor) => {
  try {
    if (!editor.selection) {
      return;
    }

    const selection = window.getSelection();
    if (selection.rangeCount) {
      let caret;

      if (getSelectedText(editor)) {
        // selected text as caret
        caret = selection.getRangeAt(0);
      } else {
        caret = ReactEditor.toDOMRange(editor, getNextLine(editor).at);
      }

      const getBoundingClientRect = () => {
        const editorContainer = document
          .querySelector("#slate-wrapper-scroll-container")
          ?.getBoundingClientRect();

        const editorEle = document
          .querySelector(".ed-section-inner")
          ?.getBoundingClientRect();

        const caretPos = caret.getBoundingClientRect();

        const isAIInputReachTop =
          caretPos.height + caretPos.y <= editorContainer.y;

        const yValue = isAIInputReachTop ? "-500" : caretPos.y; // -500 is to hide the AI input if the toolbar reached the top

        return {
          y: yValue,
          height: caretPos.height,
          top: yValue,
          right: caretPos.right,
          bottom: caretPos.bottom,

          x: editorEle.x,
          left: editorEle.left,
          width: editorEle.width,
        };
      };

      setAnchorEl({ getBoundingClientRect });
    }
  } catch (err) {
    console.log(err);
  }
};

function PopoverAIInput({ otherProps }) {
  const { services } = otherProps;
  const { openAI, setOpenAI } = useEditorContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const targetRef = useRef();

  const classes = Styles();
  const editor = useSlate();
  const [size] = useWindowResize();

  const onClickOutside = () => {
    setAnchorEl(null);
    setOpenAI("");
    setGeneratedText("");
    setLoading(false);
    setSelectedOption(null);
    setInputValue("");
    ReactEditor.focus(editor);
    Transforms.deselect(editor);
  };

  const editorElement = document.querySelector(".ed-section-inner");

  useEffect(() => {
    updateAnchorEl(setAnchorEl, editor);
  }, [openAI, editor.selection]);

  useEffect(() => {
    if (openAI === "fromToolBar") {
      scrollToAIInput();
    }
  }, [openAI]);

  const onSend = async (type, option) => {
    if (type === "close") {
      onClickOutside();
      return;
    }

    if (type === "replace_selection") {
      // replace generated text
      Transforms.insertText(editor, generatedText);
      onClickOutside();
      return;
    }

    if (type === "try_again") {
      // resetting the previous option and try again
      option = selectedOption;
      type = selectedOption.value;
    } else {
      setSelectedOption(option);
    }

    setLoading(true);

    const payload = {
      mode: option.mode || 0,
      query: inputValue,
    };

    if (option.mode === MODES.translate || option.mode === MODES.rephraseTone) {
      payload.textOptionInput = type;
    }

    if (option.mode) {
      payload.textData = generatedText || window.getSelection().toString();
    }

    const result = await services("infinityAI", payload);

    setLoading(false);
    setInputValue("");

    let { data: text } = result || {};

    if (!text) {
      onClickOutside();
      return;
    }

    if (!option.replace) {
      if (type === "continue_writing") {
        setGeneratedText(generatedText + text);
      } else {
        setGeneratedText(text);
      }

      return;
    }

    // Get the current selection point
    const { anchor } = editor.selection;

    const { path } = anchor;
    const { text: selectText } = Node.get(editor, path);

    const insertInNewLine =
      (option.isSendBtn && selectText?.length) || type === "continue_writing";

    if (insertInNewLine) {
      if (getSelectedText(editor)) {
        const currentPath = Path.parent(editor.selection.focus.path);
        const nextPath = Path.next(currentPath);

        Transforms.insertNodes(
          editor,
          { type: "paragraph", children: [{ text }] },
          { at: nextPath, select: true }
        );
      } else {
        insertAtNextLine(editor, text);
        return;
      }
    } else {
      Transforms.insertText(editor, text);
    }

    const range = {
      ...editor.selection,
      anchor: {
        ...anchor,
        offset: openAI === "fromToolBar" ? anchor.offset : 0,
      },
    };

    ReactEditor.focus(editor);
    Transforms.select(editor, range);

    // scrollToAIInput();
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div>
      {size.device === "xs" && openAI ? (
        <Box component="div" sx={classes.mobileAIInputWrapper} ref={targetRef}>
          <AIInput
            loading={loading}
            onSend={onSend}
            generatedText={generatedText}
            anchorEl={anchorEl}
            openAI={openAI}
            inputValue={inputValue}
            onInputChange={onInputChange}
            onClickOutside={onClickOutside}
          />
        </Box>
      ) : (
        <Popper
          open={Boolean(openAI)}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
          sx={{
            ...classes.aiPopper,
            width: editorElement?.offsetWidth || 400,
          }}
          ref={targetRef}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={getSelectedText(editor) ? { marginTop: "6px" } : {}}>
                <AIInput
                  loading={loading}
                  onSend={onSend}
                  generatedText={generatedText}
                  anchorEl={anchorEl}
                  openAI={openAI}
                  inputValue={inputValue}
                  onInputChange={onInputChange}
                  onClickOutside={onClickOutside}
                />
              </Paper>
            </Fade>
          )}
        </Popper>
      )}

      {/* virutal height for scrolling when ai input is opened */}
      {openAI ? (
        <div
          style={{
            height:
              targetRef?.current?.clientHeight > 250
                ? targetRef?.current?.clientHeight
                : 250,
            background: "transparent",
          }}
        ></div>
      ) : null}
    </div>
  );
}

export default PopoverAIInput;
